import googleMapApiLoader from '@/utility/map';
import { useEffect, useRef, useState } from 'react';

export interface GoogleCityResult {
  description: string;
  name?: string;
  state?: string;
  country?: string;
}

export default function useGoogleCityResult(
  value: string
): [GoogleCityResult[], boolean] {
  const [results, setResults] = useState<GoogleCityResult[]>([]);
  const [loading, setLoading] = useState(false);
  const serviceRef = useRef<google.maps.places.AutocompleteService>(null);

  useEffect(() => {
    googleMapApiLoader().then(() => {
      serviceRef.current = new google.maps.places.AutocompleteService();
    });
  }, []);

  useEffect(() => {
    if (!value) {
      setResults([]);
      setLoading(false);
      return;
    }

    if (!serviceRef.current) return;

    (async () => {
      try {
        setLoading(true);
        const data = await serviceRef.current.getPlacePredictions({
          input: value,
          componentRestrictions: {
            country: 'ca',
          },
          types: ['(cities)'],
          // fields: ['address_components', 'geometry', 'icon', 'name'],
        });

        // console.log(data);

        // const allowedTypes = ['neighborhood', 'political', 'geocode'];
        // const notAllowedTypes = ['route'];

        setResults(
          data.predictions
            // .filter(
            //   (x) =>
            //     x.types.filter((y) => allowedTypes.indexOf(y) > -1).length > 0
            // )
            // .filter(
            //   (x) =>
            //     0 ===
            //     x.types.filter((y) => notAllowedTypes.indexOf(y) > -1).length
            // )
            .map((x) => ({
              description: x.description,
              name: x.terms[0]?.value,
              state: x.terms[1]?.value,
              country: x.terms[2]?.value,
            }))
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [value]);

  return [results, loading];
}
