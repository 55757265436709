import { AppState } from '@/types/store';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';

const DialogBox = dynamic(() => import('./dialog-box'));

const DialogProvider: React.FC = () => {
  const { dialogs } = useSelector((state: AppState) => state.dialog);

  return (
    <>
      {dialogs.map((dialog) => (
        <DialogBox key={dialog.id} dialog={dialog} />
      ))}
    </>
  );
};

export default DialogProvider;
