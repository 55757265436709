import React, { SVGProps } from 'react';

export default function IconYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.3333 9.66663H10.6667C8.64162 9.66663 7 11.3082 7 13.3333V39C7 41.025 8.64162 42.6666 10.6667 42.6666H36.3333C38.3584 42.6666 40 41.025 40 39V13.3333C40 11.3082 38.3584 9.66663 36.3333 9.66663Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8333 6V13.3333"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1667 6V13.3333"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 20.6667H40"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4541 30.4766H18.8076C19.4521 30.4766 19.9297 30.3154 20.2402 29.9932C20.5508 29.6709 20.7061 29.2432 20.7061 28.71C20.7061 28.1943 20.5508 27.793 20.2402 27.5059C19.9355 27.2188 19.5137 27.0752 18.9746 27.0752C18.4883 27.0752 18.0811 27.21 17.7529 27.4795C17.4248 27.7432 17.2607 28.0889 17.2607 28.5166H14.7207C14.7207 27.8486 14.8994 27.251 15.2568 26.7236C15.6201 26.1904 16.124 25.7744 16.7686 25.4756C17.4189 25.1768 18.1338 25.0273 18.9131 25.0273C20.2666 25.0273 21.3271 25.3525 22.0947 26.0029C22.8623 26.6475 23.2461 27.5381 23.2461 28.6748C23.2461 29.2607 23.0674 29.7998 22.71 30.292C22.3525 30.7842 21.8838 31.1621 21.3037 31.4258C22.0244 31.6836 22.5605 32.0703 22.9121 32.5859C23.2695 33.1016 23.4482 33.7109 23.4482 34.4141C23.4482 35.5508 23.0322 36.4619 22.2002 37.1475C21.374 37.833 20.2783 38.1758 18.9131 38.1758C17.6357 38.1758 16.5898 37.8389 15.7754 37.165C14.9668 36.4912 14.5625 35.6006 14.5625 34.4932H17.1025C17.1025 34.9736 17.2812 35.3662 17.6387 35.6709C18.002 35.9756 18.4473 36.1279 18.9746 36.1279C19.5781 36.1279 20.0498 35.9697 20.3896 35.6533C20.7354 35.3311 20.9082 34.9062 20.9082 34.3789C20.9082 33.1016 20.2051 32.4629 18.7988 32.4629H17.4541V30.4766ZM31.376 38H28.8359V28.209L25.8037 29.1494V27.084L31.1035 25.1855H31.376V38Z"
        fill="currentColor"
      />
    </svg>
  );
}
