import React from 'react';
import { IAction, IState } from './types';

interface PropertyContextState {
  state: IState;
  dispatch: React.Dispatch<IAction>;
}

export const PropertyContext = React.createContext<PropertyContextState>({
  state: {
    initialized: false,
    view: 'list',
    filter: {
      type: 'sale',
      propertyType: [],
      city: null,
      neighborhood: null,
      keywords: null,
      minPrice: null,
      maxPrice: null,
      minBeds: null,
      maxBeds: null,
      minBaths: null,
      maxBaths: null,
      map: null,
      page: 1,
      sortBy: null,
      maxMaintenanceFee: null,
    },
    map: {
      skipCurrentLocation: false,
      properties: [],
      refreshing: false,
      position: {
        lat: null,
        lng: null,
        zoom: null,
      },
      defaultPosition: {
        lat: 0,
        lng: 0,
        zoom: 0,
      },
      currentLocation: null,
      refPropertyInfo: null,
      polygons: [],
      drawing: null,
    },
    data: {
      pagination: {
        count: 0,
        numPages: 1,
      },
      properties: [],
      refreshing: false,
    },
    hoveredProperties: [],
    hoveredArea: null,
  },
  dispatch: () => null,
});
