const Config = {
  get ENVIRONMENT() {
    return process.env.NODE_ENV;
  },
  get IS_PRODUCTION() {
    return process.env.NODE_ENV === 'production';
  },
  get BASE_URL() {
    return process.env.BASE_URL;
  },
  get API_KEY() {
    return process.env.API_KEY;
  },
  get BOARD_ID() {
    return process.env.BOARD_ID;
  },
  get CDN_URL() {
    return process.env.NEXT_PUBLIC_CDN_URL;
  },
  get API_URL() {
    return process.env.NEXT_PUBLIC_API_URL;
  },
  get APP_URL() {
    return process.env.NEXT_PUBLIC_APP_URL;
  },
  get MAP_URL() {
    return process.env.NEXT_PUBLIC_MAP_URL;
  },
  get SENDGRID_API_KEY() {
    return process.env.SENDGRID_API_KEY;
  },
  get SENDGRID_FROM_EMAIL() {
    return process.env.SENDGRID_FROM_EMAIL;
  },
  get LOCAL_STORAGE_DATA_KEY() {
    return 'REAL_ESTATE_USER_DATA';
  },
  get COOKIE_KEY() {
    return 'COOKIE_KEY';
  },
  get GOOGLE_MAP_API_KEY() {
    return 'AIzaSyALlsnrvYljbDNTuk5L1FzGMh2kb2QS_yA';
  },
  get INTERNAL_API_KEY() {
    return process.env.INTERNAL_API_KEY;
  },
  get ADMIN_EMAILS() {
    return (process.env.ADMIN_EMAILS || '').split(',').filter(Boolean);
  },
  get GOOGLE_ANALYTICS_TAG_ID() {
    return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
  },
  get SIGNIN_CLIENT_ID() {
    return process.env.NEXT_PUBLIC_SIGNIN_CLIENT_ID;
  },
  get GHOST_API_URL() {
    return process.env.GHOST_API_URL;
  },
  get GHOST_API_KEY() {
    return process.env.GHOST_API_KEY;
  },
  get JWT_SECRET() {
    return process.env.JWT_SECRET;
  },
  get ALLOWED_ORIGINS() {
    return (process.env.ALLOWED_ORIGINS || '').split(',').filter(Boolean);
  },
};

export default Config;
