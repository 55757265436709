import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constant';
import { FavoriteProperty } from './types';

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload as boolean,
      };
    },
    setFavorites: (state, action) => {
      return {
        ...state,
        favorites: action.payload as FavoriteProperty[],
      };
    },
    addFavorite: (state, action) => {
      return {
        ...state,
        favorites: [
          ...state.favorites,
          {
            createdOn: new Date().toISOString(),
            mlsNumber: action.payload as string,
            addedBy: '',
            message: '',
          },
        ],
      };
    },
    removeFavorite: (state, action) => {
      return {
        ...state,
        favorites: state.favorites.filter(
          (x) => x.mlsNumber !== (action.payload as string)
        ),
      };
    },
    setPopupMlsNumber: (state, action) => {
      return {
        ...state,
        popupMlsNumber: action.payload as string,
      };
    },
  },
});

export const favoriteActions = favoriteSlice.actions;
