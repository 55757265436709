import { AppState } from '@/types/store';
import { useSelector } from 'react-redux';
import { AuthState } from '../types';

export function useAuth() {
  const auth = useSelector<AppState, AuthState>((state) => state.auth);
  const { loading, isAuthenticated, authInfo, hasTableViewAccess } = auth;

  return {
    loading,
    isAuthenticated,
    authInfo,
    hasTableViewAccess,
  };
}
