import { AuthState } from './types';

export const initialState: AuthState = {
  loading: true,
  isAuthenticated: false,
  authInfo: null,
  hasTableViewAccess: false,
  preference: null,
  preferenceLoading: true,
};
