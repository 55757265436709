import Config from '@/config';
import { AppObject } from '@/types';
import {
  getMapApiLink,
  transformObjectToQuery,
  transformProperty,
} from '@/utility';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { ACTION } from '../action';
import { DEFAULT_ZOOM } from '../constant';
import { IAction, IMapPosition, IState } from '../types';

export const useMapDataLoader = (
  state: IState,
  dispatch: React.Dispatch<IAction>
) => {
  const { initialized, filter, view, map } = state;
  // const ref = useRef(false);
  const requestRef = useRef(null);
  const mapRef = useRef(map);
  mapRef.current = map;

  useEffect(() => {
    if (!initialized) return;
    // if (!ref.current) {
    //   ref.current = true;
    //   return;
    // }

    if (view !== 'map') {
      dispatch({
        type: ACTION.SET_MAP_PROPERTIES,
        payload: [],
      });
      return;
    }

    (async () => {
      if (requestRef.current) {
        requestRef.current.cancel('Request cancelled');
      }

      const query = getMapApiLink(
        {
          type: filter.type,
          // class: filter.class,
          propertyType: filter.propertyType,
          city: filter.city,
          neighborhood: filter.neighborhood,
          keywords: filter.keywords,
          minPrice: filter.minPrice,
          maxPrice: filter.maxPrice,
          minBeds: filter.minBeds,
          maxBeds: filter.maxBeds,
          minBaths: filter.minBaths,
          maxBaths: filter.maxBaths,
          listedInDays: filter.listedInDays,
          map: filter.map,
          maxMaintenanceFee: filter.maxMaintenanceFee,
          distress: filter.distress,
        },
        map.drawing
      );

      dispatch({
        type: ACTION.SET_MAP_REFRESHING,
        payload: true,
      });

      const cancelToken = axios.CancelToken;
      requestRef.current = cancelToken.source();

      try {
        const response = await axios
          .get(
            `${Config.API_URL}/api/repliers/listings?${transformObjectToQuery(
              query
            )}`,
            {
              cancelToken: requestRef.current.token,
            }
          )
          .then((x) => x.data);

        dispatch({
          type: ACTION.SET_MAP_PROPERTIES,
          payload: (response.listings || []).map(transformProperty),
        });

        dispatch({
          type: ACTION.SET_MAP_REFRESHING,
          payload: false,
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    })();
  }, [
    dispatch,
    initialized,
    filter.type,
    // filter.class,
    filter.propertyType,
    filter.map,
    filter.city,
    filter.neighborhood,
    filter.keywords,
    filter.minPrice,
    filter.maxPrice,
    filter.minBeds,
    filter.maxBeds,
    filter.minBaths,
    filter.maxBaths,
    filter.listedInDays,
    view,
    map.drawing,
    filter.maxMaintenanceFee,
    filter.distress,
  ]);

  useEffect(() => {
    if (!initialized) return;

    if (view !== 'map') {
      dispatch({
        type: ACTION.SET_MAP_POLYGONS,
        payload: [],
      });
      return;
    }

    if (
      !filter.neighborhood &&
      !filter.city &&
      !map.refPropertyInfo?.neighborhood
    ) {
      if (
        mapRef.current.drawing &&
        (mapRef.current.drawing.circle || mapRef.current.drawing.polygons)
      ) {
        return;
      }

      dispatch({
        type: ACTION.SET_MAP_POLYGONS,
        payload: [],
      });
      return;
    }

    (async () => {
      const query: AppObject = {};

      let skipCenter = false;
      if (filter.city || filter.neighborhood) {
        query.city = filter.city;
        query.neighborhood = filter.neighborhood;
      } else {
        query.neighborhood = map.refPropertyInfo.neighborhood;
        skipCenter = true;
      }

      const response = await axios
        .get(
          `${Config.API_URL}/api/listing/location?${transformObjectToQuery(
            query
          )}`
        )
        .then((x) => x.data);

      if (!skipCenter) {
        dispatch({
          type: ACTION.SET_MAP_DEFAULT_POSITION,
          payload: {
            lat: response.location.lat,
            lng: response.location.lng,
            zoom: DEFAULT_ZOOM,
          } as IMapPosition,
        });

        dispatch({
          type: ACTION.SET_MAP_POSITION,
          payload: {
            lat: response.location.lat,
            lng: response.location.lng,
            zoom: DEFAULT_ZOOM,
          } as IMapPosition,
        });
      }

      dispatch({
        type: ACTION.SET_MAP_POLYGONS,
        payload: response.coordinates || [],
      });
    })();
  }, [
    dispatch,
    initialized,
    view,
    filter.city,
    filter.neighborhood,
    map.refPropertyInfo?.neighborhood,
  ]);

  return null;
};
