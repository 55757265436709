import React, { useEffect } from 'react';
import NextNProgress from 'nextjs-progressbar';
import { DefaultSeo } from 'next-seo';

import SEO from '@/config/seo.json';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { DefaultLayout } from '@/components/layouts';

import '@/styles/globals.css';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '@material/react-linear-progress/dist/linear-progress.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AnalyticsService from '@/services/analytics.service';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', AnalyticsService.pageview);

    return () => {
      router.events.off('routeChangeComplete', AnalyticsService.pageview);
    };
  }, [router.events]);

  if (
    router.pathname === '/payment-calculator-widget' ||
    router.pathname === '/land-transfer-tax-calculator-widget' ||
    router.pathname === '/affordability-calculator-widget' ||
    router.pathname === '/auth/token/[token]' ||
    router.pathname === '/unsubscribe' ||
    router.pathname.startsWith('/debug')
  ) {
    return <Component {...pageProps} />;
  }

  return (
    <DefaultLayout>
      {router.pathname !== '/properties' && (
        <NextNProgress
          color="#00a09b"
          startPosition={0.3}
          stopDelayMs={200}
          height={6}
        />
      )}
      <DefaultSeo {...SEO} />
      <Component {...pageProps} />
    </DefaultLayout>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { id, name, label, value } = metric;
  AnalyticsService.event(name, {
    event_category:
      label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
}

export default MyApp;
