import fetch, { Response } from 'node-fetch';

import config from '@/config';
import EventService from './event.service';
import { withRetry } from '@/utility';
import BroadcastService from './broadcast.service';
import { Property } from '@/types';

export const AUTH_KEY = 'jdr_auth';

export default class AuthService {
  public async oneTapSignin<T = object>(body: T) {
    const response = await withRetry<Response>(() =>
      fetch(`${config.API_URL}/api/one-tap-signin`, {
        headers: this.getHeaders(),
        method: 'POST',
        body: JSON.stringify(body),
      })
    );

    if (response.status !== 200) {
      throw new Error('Unable to signin');
    }

    const data = await response.json();

    return data;
  }

  public async googleLogin({ tokenId }) {
    const response = await withRetry<Response>(() =>
      fetch(`${config.API_URL}/api/google-signin`, {
        headers: this.getHeaders(),
        method: 'POST',
        body: JSON.stringify({
          tokenId,
        }),
      })
    );

    if (response.status !== 200) {
      throw new Error('Unable to signin');
    }

    const data = await response.json();

    return data;
  }

  public async saveAuth(auth: object) {
    localStorage.setItem('jdr_auth', JSON.stringify(auth));
    EventService.emit(EventService.EVENT.AUTH_CHANGE);
    BroadcastService.getInstance().broadcast(
      BroadcastService.EVENT.AUTH_CHANGE,
      null
    );
  }

  public async openAuthModel(disableClose?: boolean) {
    EventService.emit('CHANGE_SIGNIN_MODEL_STATE', {
      open: true,
      disabled: disableClose,
    });
  }

  public async signout() {
    localStorage.removeItem(AUTH_KEY);
    document.cookie =
      'jdr_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;';
    EventService.emit(EventService.EVENT.AUTH_CHANGE);
    BroadcastService.getInstance().broadcast(
      BroadcastService.EVENT.AUTH_CHANGE,
      null
    );
  }

  private getHeaders() {
    return {
      'content-type': 'application/json',
    };
  }

  public async addVisitedPropertyInQuey(property: Property) {
    const str = localStorage.getItem('visited-properties-queue');
    const visitedPropertyInQueue: unknown[] = JSON.parse(str || '[]');
    visitedPropertyInQueue.push({
      mlsNumber: property.mlsNumber,
      visitedOn: new Date().toISOString(),
    });

    // max queue length is 50
    while (visitedPropertyInQueue.length > 50) {
      visitedPropertyInQueue.shift();
    }

    localStorage.setItem(
      'visited-properties-queue',
      JSON.stringify(visitedPropertyInQueue)
    );
  }

  public async flushVisitedPropertyInQuey(email: string) {
    try {
      if (!email) return;
      const str = localStorage.getItem('visited-properties-queue');
      const visitedPropertyInQueue: unknown[] = JSON.parse(str || '[]');

      if (!visitedPropertyInQueue?.length) return;

      localStorage.removeItem('visited-properties-queue');

      await withRetry<Response>(() =>
        fetch(`${config.API_URL}/api/add-visited-properties`, {
          headers: this.getHeaders(),
          method: 'POST',
          body: JSON.stringify({
            email,
            data: visitedPropertyInQueue,
          }),
        })
      );
    } catch (e) {
      console.log(e);
    }
  }
}
