import useRouteQuery from '@/hooks/use-route-query';
import { useEffect, useRef } from 'react';
import { ACTION } from '../action';
import { IAction, IState } from '../types';

export const useStateUpdater = (
  state: IState,
  dispatch: React.Dispatch<IAction>
) => {
  const { query } = useRouteQuery();
  const { initialized, filter } = state;
  const ref = useRef(filter);
  const cityslug = (query as any).cityslug as string;
  // const cityslugRef = useRef(cityslug);

  // console.log(cityslug);

  useEffect(() => {
    ref.current = filter;
    // cityslugRef.current = cityslug;
  }, [filter]);

  useEffect(() => {
    if (!initialized) return;

    const type = query.type || 'sale';

    if (ref.current.type !== type) {
      dispatch({
        type: ACTION.FILTER_UPDATE,
        payload: {
          type: type,
        },
      });
    }

    const city = cityslug?.replace('-real-estate', '');
    if (city && ref.current.city !== city) {
      dispatch({
        type: ACTION.FILTER_UPDATE,
        payload: {
          city,
        },
      });
    }
  }, [ref, initialized, dispatch, query.type, query.class, cityslug]);
};
