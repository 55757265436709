import React from 'react';

export interface ClickAwayProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  onClickAway: () => void;
  value?: boolean;
}

export const ClickAway: React.FC<ClickAwayProps> = ({
  children,
  onClickAway,
  value,
  ...rest
}) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickAway();
      }
    };

    window.addEventListener('mousedown', handleOutsideClick);
    return () => window.removeEventListener('mousedown', handleOutsideClick);
  }, [ref, value, onClickAway]);

  React.useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClickAway();
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [value, onClickAway]);

  return (
    <div ref={ref} id="click-away" {...rest}>
      {children}
    </div>
  );
};
