import useRouteQuery from '@/hooks/use-route-query';
import { filterToQuery, transformObjectToQuery } from '@/utility';
import { useEffect, useRef } from 'react';
import { IState } from '../types';

let urlUpdateCount = 0;
setInterval(() => (urlUpdateCount = 0), 10000);

function ensureRoutingBehavior() {
  if (urlUpdateCount > 50) {
    throw new Error('Too many url updates');
  }
}

export const useUrlUpdater = (state: IState) => {
  const { query, updateQuery, router } = useRouteQuery();
  const { initialized } = state;
  const ref = useRef(false);
  const queryRef = useRef(query);
  const routerRef = useRef(router);

  useEffect(() => {
    queryRef.current = query;
    routerRef.current = router;
  }, [query, router]);

  useEffect(() => {
    if (!initialized) return;
    if (!ref.current) {
      ref.current = true;
      return;
    }

    let pathname: string = null;

    const cityslug = routerRef.current.query.cityslug as string;
    const neighborhoodslug = routerRef.current.query.neighborhoodslug as string;

    if (neighborhoodslug && neighborhoodslug !== state.filter.neighborhood) {
      pathname = '/properties';
    } else if (!neighborhoodslug && cityslug) {
      const city = cityslug.replace('-real-estate', '').toLowerCase();

      if (state.filter.city?.toLowerCase() !== city) {
        pathname = '/properties';
      }
    }

    const oldQuery = queryRef.current;
    const newQuery = filterToQuery(
      state.filter,
      state.view,
      state.map.position,
      state.map.drawing
    );

    if (
      transformObjectToQuery(oldQuery) === transformObjectToQuery(newQuery) &&
      !pathname
    ) {
      return;
    }

    // console.log('update query');
    // // console.log(oldQuery, newQuery);
    // console.log(
    //   transformObjectToQuery(oldQuery),
    //   transformObjectToQuery(newQuery)
    // );

    // return;

    ensureRoutingBehavior();
    urlUpdateCount++;

    updateQuery(newQuery, true, true, pathname);
  }, [
    initialized,
    updateQuery,
    state.filter,
    state.view,
    state.map,
    state.data.pagination,
  ]);
};
