import Config from '@/config';
import { Loader } from '@googlemaps/js-api-loader';

let loader_: Loader;
let loadPromise_: Promise<typeof google.maps>;

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const bootstrapURLKeys: { key: string; libraries: Libraries } = {
  key: Config.GOOGLE_MAP_API_KEY,
  libraries: ['drawing', 'places'],
};

export default function googleMapApiLoader() {
  // avoid api to be loaded multiple times
  if (loadPromise_) {
    return loadPromise_;
  }

  if (!bootstrapURLKeys.libraries) {
    bootstrapURLKeys.libraries = [];
  }

  const libraries = [...bootstrapURLKeys.libraries];

  const { key, ...restKeys } = bootstrapURLKeys;

  // use single instance of Loader to avoid multiple api loads
  if (!loader_) {
    loader_ = new Loader({
      // need to keep key as a prop for backwards compatibility
      apiKey: key || '',
      ...restKeys,
      libraries,
    });
  }

  loadPromise_ = loader_.load().then(() => {
    return window.google.maps;
  });

  return loadPromise_;
}
