import { cities } from '@/utility/constants';
import Link from 'next/link';

const CitySection: React.FC = () => {
  return (
    <div>
      <div className="p-1 mb-4 text-lg">HOME FOR SALE BY CITY</div>
      <ul className="overflow-auto max-h-48">
        {cities.map((item, index) => (
          <li key={index} className="p-1">
            <Link href={`/properties/${item}-real-estate`}>
              <a className="hover:text-primary-500 hover:underline">{item}</a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CitySection;
