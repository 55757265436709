export const cities = [
  'Toronto',
  'Brampton',
  'Mississauga',
  'Oakville',
  'Milton',
  'Barrie',
  'Burlington',
  'Orangeville',
  'Caledon',
  'Halton Hills',
  'Woodstock',
  'Kitchener',
  'Cambridge',
  'Waterloo',
  'Markham',
  'Vaughan',
  'Richmond Hill',
  'Aurora',
  'Innisfil',
  'New Tecumseth',
  'Whitchurch-Stouffville',
  'Georgina',
  'Newmarket',
  'King',
  'Bradford West Gwillimbury',
];
