export default class LocalStorage {
  static get(key: string) {
    return localStorage.getItem(key);
  }
  /**
   * @param key
   * @param data
   * @description Set in local storage
   */
  static set(key: string, data: string) {
    return localStorage.setItem(key, data);
  }
}
