import React, { SVGProps } from 'react';

export default function IconYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.38043 18.4324H15.8098V38H9.38043V18.4324ZM12.6381 9C10.4376 9 9 10.461 9 12.3787C9 14.2569 10.3956 15.7604 12.554 15.7604H12.595C14.8376 15.7604 16.2341 14.2568 16.2341 12.3787C16.1921 10.461 14.8376 9 12.6381 9ZM31.5956 17.9728C28.1829 17.9728 26.6539 19.8712 25.801 21.2026V18.4324H19.3697C19.4549 20.2681 19.3697 38 19.3697 38H25.801V27.0721C25.801 26.4868 25.8431 25.9037 26.0123 25.4844C26.4779 24.316 27.5361 23.1061 29.311 23.1061C31.6397 23.1061 32.5697 24.9013 32.5697 27.5306V37.9999H39V26.7795C39 20.7693 35.8284 17.9728 31.5956 17.9728Z"
        fill="currentColor"
      />
    </svg>
  );
}
