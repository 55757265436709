export function toNumber(value: string): number | null {
  if (!value) return null;
  if (value === 'null' || value === 'N/A') return null;
  return Number(value);
}

const defaultFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function formatCurrency(amount: number, formatter?: Intl.NumberFormat) {
  return (formatter || defaultFormatter).format(amount);
}

export function cleanObject<T extends Record<string, unknown>>(obj: T): T {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (
      !Array.isArray(value) &&
      value !== null &&
      value !== undefined &&
      value !== ''
    ) {
      acc[key] = value;
    } else if (Array.isArray(value) && value.length) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, unknown>) as T;
}

export function formatCash(input?: number) {
  const n = input || 0;
  if (n < 1e3) return String(n);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
}
