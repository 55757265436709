import { DropdownData } from '@/components/ui/dropdown-container';
// import Config from '@/config';
// import axios from 'axios';

export const PropertyTypes: DropdownData[] = [
  {
    label: 'Residential',
    value: 'residential',
  },
  {
    label: 'Condo',
    value: 'condo',
  },
  {
    label: 'Commercial',
    value: 'commercial',
  },
];

export const Beds: DropdownData[] = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '1+',
    value: '1+',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '2+',
    value: '2+',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '3+',
    value: '3+',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '4+',
    value: '4+',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '5+',
    value: '5+',
  },
];

export const Baths = [...Beds];

const priceOptions: DropdownData[] = [];
const leasePriceOptions: DropdownData[] = [];

for (let i = 0; i < 20000000; ) {
  if (i < 1000000) {
    i += 25000;
  } else if (i < 2000000) {
    i += 100000;
  } else if (i < 7500000) {
    i += 500000;
  } else if (i < 10000000) {
    i += 2500000;
  } else if (i < 20000000) {
    i += 5000000;
  } else {
    break;
  }
  priceOptions.push({
    label: `$${i}`,
    value: String(i),
  });
}

for (let i = 0; i < 20000; ) {
  if (i < 1000) {
    i += 25;
  } else if (i < 2000) {
    i += 100;
  } else if (i < 10000) {
    i += 500;
  } else if (i < 20000) {
    i += 5000;
  } else {
    break;
  }
  leasePriceOptions.push({
    label: `$${i}`,
    value: String(i),
  });
}

export const MinPrice: DropdownData[] = [
  {
    label: '$0',
    value: '',
  },
  ...priceOptions,
];
export const MaxPrice: DropdownData[] = [
  {
    label: 'Unlimited',
    value: '',
  },
  ...priceOptions,
];

export const LeaseMinPrice: DropdownData[] = [
  {
    label: '$0',
    value: '',
  },
  ...leasePriceOptions,
];
export const LeaseMaxPrice: DropdownData[] = [
  {
    label: 'Unlimited',
    value: '',
  },
  ...leasePriceOptions,
];

export const Type: DropdownData[] = [
  // {
  //   label: "Sale & Lease",
  //   value: "",
  // },
  {
    label: 'Sale',
    value: 'sale',
  },
  {
    label: 'Lease',
    value: 'lease',
  },
  {
    label: 'Sold',
    value: 'sold',
  },
  {
    label: 'Leased',
    value: 'leased',
  },
];

export const SortBy: DropdownData[] = [
  {
    label: 'Sort By',
    value: '',
    disabled: true,

    // console.log(cities());
  },
  // {
  //   label: "Created On (Asc)",
  //   value: "createdOnAsc",
  // },
  // {
  //   label: "Created On (Desc)",
  //   value: "createdOnDesc",
  // },
  // {
  //   label: "Updated On (Asc)",
  //   value: "updatedOnAsc",
  // },
  // {
  //   label: "Updated On (Desc)",
  //   value: "updatedOnDesc",
  // },
  // {
  //   label: "List Price (Asc)",
  //   value: "listPriceAsc",
  // },
  // {
  //   label: "List Price (Desc)",
  //   value: "listPriceDesc",
  // },
  // {
  //   label: "Sold Price (Asc)",
  //   value: "soldPriceAsc",
  // },
  // {
  //   label: "Sold Price (Desc)",
  //   value: "soldPriceDesc",
  // },
  // {
  //   label: "Sold Date (Asc)",
  //   value: "soldDateAsc",
  // },
  // {
  //   label: "Sold Date (Desc)",
  //   value: "soldDateDesc",
  // },

  {
    //   label: '',
    //   value: 'createdOnAsc',
    // }, {
    label: 'Recently Listed',
    value: 'createdOnDesc',
  },
  {
    //   label: '',
    //   value: 'updatedOnAsc',
    // }, {
    label: 'Recently Updated',
    value: 'updatedOnDesc',
  },
  {
    label: 'Price High-Low',
    value: 'listPriceDesc',
  },
  {
    label: 'Price Low-High',
    value: 'listPriceAsc',
    // }, {
    //   label: '',
    //   value: 'soldDateAsc',
    // }, {
    //   label: '',
    //   value: 'soldDateDesc',
    // }, {
    //   label: '',
    //   value: 'soldPriceAsc',
    // }, {
    //   label: '',
    //   value: 'soldPriceDesc',
  },
];

export const View: DropdownData[] = [
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'Map',
    value: 'map',
  },
];

export const SortBySold: DropdownData[] = [
  {
    label: 'Sort By',
    value: '',
    disabled: true,
  },
  {
    label: 'Recently Sold',
    value: 'soldDateDesc',
  },
  {
    label: 'Recently Updated',
    value: 'updatedOnDesc',
  },
  {
    label: 'Price High-Low',
    value: 'soldPriceDesc',
  },
  {
    label: 'Price Low-High',
    value: 'soldPriceAsc',
  },
];

export const ListedInDaysOptions: DropdownData[] = [
  {
    label: 'Listed',
    value: '',
    disabled: true,
  },
  {
    label: 'Today',
    value: '1',
  },
  {
    label: '1 day back',
    value: '2',
  },
  {
    label: 'In last 7 days',
    value: '7',
  },
  {
    label: 'In last 14 days',
    value: '14',
  },
  {
    label: 'In last 30 days',
    value: '30',
  },
  {
    label: 'In last 180 days',
    value: '180',
  },
];

export const MaintanceFeesOptions: DropdownData[] = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'Less than $400',
    value: '400',
  },
  {
    label: 'Less than $500',
    value: '500',
  },
  {
    label: 'Less than $600',
    value: '600',
  },
  {
    label: 'Less than $700',
    value: '700',
  },
  {
    label: 'Less than $800',
    value: '800',
  },
  {
    label: 'Less than $900',
    value: '900',
  },
  {
    label: 'Less than $1000',
    value: '1000',
  },
  {
    label: 'Less than $2000',
    value: '2000',
  },
  {
    label: 'Less than $5000',
    value: '5000',
  },
];
