import React, { useState } from 'react';
import NextImage, { ImageProps } from 'next/image';

export function Image(props: ImageProps) {
  const [error, setError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const src: any = error ? '/img/logo.png' : props.src;
  if (!props.src) {
    return null;
  }
  return (
    <NextImage
      {...props}
      src={src}
      onError={() => setError(true)}
      objectFit={error ? 'contain' : props.objectFit}
    />
  );
}
