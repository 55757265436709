import { Property, PropertyId } from '@/types';
import { useCallback } from 'react';
import { HoveredArea } from '..';
import { ACTION } from '../action';
import { IFilter, IMapPosition, IPagination, IState, View } from '../types';
import { usePropertyContext } from './use-property-context';

let hoverPropertyHandler = null;

export const usePropertyAction = () => {
  const { state, dispatch } = usePropertyContext();

  const setRefreshing = useCallback(
    (value: boolean) =>
      dispatch({
        type: ACTION.SET_LOADING,
        payload: value,
      }),
    [dispatch]
  );

  const setPagination = useCallback(
    (value: IPagination) =>
      dispatch({
        type: ACTION.SET_PAGINATION,
        payload: value,
      }),
    [dispatch]
  );

  const setProperties = useCallback(
    (value: Property[]) =>
      dispatch({
        type: ACTION.SET_PROPERTIES,
        payload: value,
      }),
    [dispatch]
  );

  const setMapProperties = useCallback(
    (value: Property[]) => {
      dispatch({
        type: ACTION.SET_MAP_PROPERTIES,
        payload: value,
      });
    },
    [dispatch]
  );

  const setMapRefreshing = useCallback(
    (value: boolean) => {
      dispatch({
        type: ACTION.SET_MAP_REFRESHING,
        payload: value,
      });
    },
    [dispatch]
  );

  const setMapPosition = useCallback(
    (value: IMapPosition) => {
      dispatch({
        type: ACTION.SET_MAP_POSITION,
        payload: value,
      });
      dispatch({
        type: ACTION.SET_MAP_DEFAULT_POSITION,
        payload: value,
      });
    },
    [dispatch]
  );

  const filterUpdate = useCallback(
    (value: Partial<IFilter>) => {
      dispatch({
        type: ACTION.FILTER_UPDATE,
        payload: value,
      });
    },
    [dispatch]
  );

  const changeView = useCallback(
    (value: View) => {
      // updateQuery(
      //   {
      //     ...query,
      //     view: value,
      //     zoom: null,
      //     lat: null,
      //     lng: null,
      //     map: null,
      //     pageNum: null,
      //   },
      //   true,
      //   true
      // );
      localStorage.setItem('selectedView', value);
      dispatch({
        type: ACTION.UPDATE_STATE,
        payload: {
          ...state,
          view: value,
          filter: {
            ...state.filter,
            map: null,
            pageNum: 1,
          },
          map: {
            ...state.map,
            properties: [],
            refreshing: false,
            position: {
              lat: value === 'map' ? state.map.defaultPosition.lat : null,
              lng: value === 'map' ? state.map.defaultPosition.lng : null,
              zoom: value === 'map' ? state.map.defaultPosition.zoom : null,
            },
            polygons: [],
            refPropertyInfo: null,
          },
        } as IState,
      });
    },
    [state, dispatch]
  );

  const changeHoveredInfo = useCallback(
    (properties: PropertyId[], area: HoveredArea) => {
      if (hoverPropertyHandler) {
        clearTimeout(hoverPropertyHandler);
      }

      hoverPropertyHandler = setTimeout(() => {
        if (
          JSON.stringify(properties) ===
            JSON.stringify(state.hoveredProperties) &&
          area === state.hoveredArea
        ) {
          return;
        }

        dispatch({
          type: ACTION.SET_HOVERED_PROPERTIES_INFO,
          payload: {
            hoveredArea: area,
            hoveredProperties: properties,
          } as Pick<IState, 'hoveredProperties' | 'hoveredArea'>,
        });
      }, 100);
    },
    [state.hoveredProperties, state.hoveredArea, dispatch]
  );

  const clearRefNeighborhood = useCallback(() => {
    dispatch({
      type: ACTION.SET_MAP_CLEAR_REF_NEIGHBORHOOD,
      payload: null,
    });
  }, [dispatch]);

  return {
    setProperties,
    setRefreshing,
    setMapProperties,
    setPagination,
    setMapRefreshing,
    setMapPosition,
    filterUpdate,
    changeView,
    changeHoveredInfo,
    clearRefNeighborhood,
    dispatch,
  };
};
