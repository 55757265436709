import React, { SVGProps } from 'react';

export default function IconEmail(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.00001 39.2249C3.89544 39.2249 3.00001 38.3295 3.00001 37.2249V11C3.00001 9.89543 3.89544 9 5.00001 9H42C43.1046 9 44 9.89543 44 11V37.2249C44 38.3295 43.1046 39.2249 42 39.2249H5.00001Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M43.862 9.99326L26.0074 27.8808C24.684 29.1993 22.2758 29.1837 20.9804 27.8928L3.13803 10.1372"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M42.8687 38.798L28.963 23.899"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M4.1313 38.798L18.037 24.8923"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
}
