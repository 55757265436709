import { usePropertyAction } from './use-property-action';
import { usePropertyData } from './use-property-data';

export const useProperty = () => {
  const { state, view, filter, data, map } = usePropertyData();
  const {
    dispatch,
    setProperties,
    setRefreshing,
    setMapProperties,
    setPagination,
    setMapRefreshing,
    setMapPosition,
    changeView,
    filterUpdate,
    changeHoveredInfo,
    clearRefNeighborhood,
  } = usePropertyAction();

  return {
    view,
    filter,
    data,
    map,
    properties: data.properties,
    refreshing: data.refreshing,
    mapProperties: map.properties,
    pagination: data.pagination,
    mapRefreshing: map.refreshing,
    state,
    setProperties,
    setRefreshing,
    setMapProperties,
    setPagination,
    setMapRefreshing,
    setMapPosition,
    filterUpdate,
    changeView,
    changeHoveredInfo,
    clearRefNeighborhood,
    dispatch,
  };
};
