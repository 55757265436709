import { Image } from '@/components/ui/image';
import { Link } from '@/components/ui/link';
import clsx from 'clsx';
import { SocialLinks } from '../social-links';

import InfoJson from '@/config/info.json';

import style from './footer.module.css';
import { FORMAT_YEAR, formatDate } from '@/utility/date';
import CitySection from './city-section';
import LanguageSwitcher from './language-switcher';

export function Footer() {
  return (
    <footer>
      <div className="flex items-center relative mt-4 px-4 lg:px-8 mb-10 justify-center">
        <div
          className={clsx(
            'absolute -translate-x-1/2 hidden lg:block pointer-events-none',
            style['footer-watermark']
          )}
        >
          <div className={clsx('relative h-80 w-60')}>
            <Image src="/img/watermark.png" layout="fill" alt="Watermark" />
          </div>
        </div>
        <div className="grid md:grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="w-full">
            <CitySection />
            {/* <div className="h-96 bg-white py-13 px-7 border-4 border-primary-500 flex  justify-center flex-col text-center newsletter-bg w-full">
              <NewsletterForm />
            </div> */}
          </div>
          <div>
            <div className="flex items-center justify-center flex-col h-full">
              <h2 className="text-3xl">Jen Dsouza</h2>
              <a
                href={`tel: ${InfoJson.contactNo}`}
                title={`Call : ${InfoJson.contactNo}`}
                className="my-2 px-2"
              >
                <p className="text-gray-600">{InfoJson.contactNo}</p>
              </a>
              <a
                href={`mailto:${InfoJson.email}`}
                title={`Email @ ${InfoJson.email}`}
                className="mb-2 px-2"
              >
                <p className="text-gray-600">{InfoJson.email}</p>
              </a>
              <SocialLinks className="mt-4 text-secondary-500" />
              <div className="max-w-xs text-center mt-6 leading-6">
                <p style={{ whiteSpace: 'nowrap' }}>
                  {InfoJson.footeraddress1}
                  <br />
                  {InfoJson.footeraddress2}
                  <br />
                  {InfoJson.footeraddress3}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center flex-col">
            {/* <div className="relative h-36 md:h-52 w-64 md:w-96">
                <Image
                  src="/img/logo.png"
                  layout="fill"
                  objectFit="contain"
                  alt="Logo"
                />
              </div> */}
            <div className="relative h-36 md:h-40 w-80 md:w-96">
              <Image
                src="/img/RoyalLepage-real-estate-services-logo_1G2P1zM.png"
                layout="fill"
                objectFit="contain"
                alt="Real Estate Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-white py-10">
        <div className="grid md:grid-cols-3 px-8 space-y-2">
          <div className="text-center md:text-left">
            <p>
              &copy;Copyright {formatDate(new Date(), FORMAT_YEAR)} Jen Dsouza
              all Rights Reserved
            </p>
          </div>
          <div className="text-center">
            <LanguageSwitcher />
          </div>
          <div className="flex items-center justify-center md:justify-end space-x-3">
            <Link href="/privacy" prefetch={false}>
              Privacy Policy
            </Link>
            <span>/</span>
            <Link href="/tos" prefetch={false}>
              &nbsp;Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
