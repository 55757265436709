export const DesktopLinks = [
  {
    path: '/properties?type=sale',
    name: 'Buy',
  },
  {
    path: '/properties?type=lease',
    name: 'Rent',
  },
  {
    path: '/properties?type=sold',
    name: 'Sold',
  },
  {
    path: '/video',
    name: 'Videos',
  },
  // {
  //   path: "/blog",
  //   name: "Blogs",
  // },
  {
    path: '/about',
    name: 'About',
  },
];
