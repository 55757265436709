export const paths = {
  '/': {
    title: 'Home',
  },
  '/know-us': {
    title: 'Know Us',
  },
  '/about': {
    title: 'About',
  },
  '/testimonials': {
    title: 'Testimonials',
  },
  '/blog': {
    title: 'Blog',
  },
  '/buying-with': {
    title: 'Buying With',
  },
  '/selling-with-us': {
    title: 'Selling With Us',
  },
  '/neighbourhood-guides': {
    title: 'Neighbourhood Guide',
  },
  '/condo-guide': {
    title: 'Condo Guide',
  },
  '/properties': {
    title: 'Properties',
  },
  '/properties/sell': {
    title: 'Sell Properties',
  },
  '/detail/[id]': {
    title: 'Property Detail',
  },
};
