import { FC } from 'react';
import useScript from '@/components/one-tap-sign-in/useScript';

const LanguageSwitcher: FC = () => {
  useScript(
    '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  );

  return (
    <>
      <div id="google_translate_element"></div>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            function googleTranslateElementInit() {
              new google.translate.TranslateElement({pageLanguage: 'en', includedLanguages: 'kn,hi'}, 'google_translate_element');
            }`,
        }}
      />
    </>
  );
};

export default LanguageSwitcher;
