import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './constant';
import { CompareProperty } from './types';

export const compareSlice = createSlice({
  name: 'compare',
  initialState: initialState,
  reducers: {
    setItems: (state, action) => {
      return {
        ...state,
        items: action.payload as CompareProperty[],
      };
    },
    addItem: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        items: [
          ...state.items,
          {
            mlsNumber: action.payload,
          },
        ],
      };
    },
    removeItem: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        items: state.items.filter((x) => x.mlsNumber !== action.payload),
      };
    },
    removeAll: (state) => {
      return {
        ...state,
        items: [],
      };
    },
  },
});

export const compareActions = compareSlice.actions;
