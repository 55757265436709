import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import BroadcastService from '@/services/broadcast.service';
import NetworkService from '@/services/network.service';
import { AppState } from '@/types/store';
import { sentryCaptureException } from '@/utility';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { favoriteActions } from '../slice';
import { FavoriteProperty, FavoriteState } from '../types';

export async function fetchFavoriteProperties() {
  try {
    const service = new NetworkService();
    const result: FavoriteProperty[] = await service.get(`/api/favorites`);
    return result;
  } catch (err) {
    sentryCaptureException(err);
    return [];
  }
}

export async function fetchFavoritePropertyUserWise(mlsNumber: string) {
  try {
    const service = new NetworkService();
    const result = await service.get(
      `/api/favorites/userwise?mlsNumber=${mlsNumber}`
    );

    return result as Array<{
      email: string;
      name: string;
      isFavorite: boolean;
    }>;
  } catch (err) {
    sentryCaptureException(err);
    return [];
  }
}

export async function updateFavoriteById(
  mlsNumber: string,
  isFavorite: boolean,
  message?: string
) {
  const service = new NetworkService();
  await service.post(
    `/api/favorites?mlsNumber=${mlsNumber}&isFavorite=${isFavorite}&message=${encodeURIComponent(
      message || ''
    )}`,
    {}
  );
}

export async function updateFavoriteByIdForUser(
  customerId: string,
  mlsNumber: string,
  isFavorite: boolean,
  message?: string
) {
  const service = new NetworkService();
  await service.post(
    `/api/favorites?customerId=${customerId}&mlsNumber=${mlsNumber}&isFavorite=${isFavorite}&message=${encodeURIComponent(
      message || ''
    )}`,
    {}
  );
}

export interface UserPropertyResponse {
  isContextAvailable: boolean;
  isFavorite: boolean;
  toggleFavorite: () => void;
  loading: boolean;
}

const useFavoriteInternal = () => {
  const dispatch = useDispatch();
  const state = useSelector<AppState, FavoriteState>((state) => state.favorite);
  const { isAuthenticated, authInfo } = useAuth();

  const isFavoriteFn = useCallback(
    (mlsNumber: string) => {
      return !!state.favorites.find(
        (property) => property.mlsNumber === mlsNumber
      );
    },
    [state.favorites]
  );

  const toggleFavorite = useCallback(
    async (mlsNumber: string, message?: string) => {
      if (state.loading) return;

      if (!isAuthenticated) {
        const authService = new AuthService();
        authService.openAuthModel();
      } else {
        const isFavorite = isFavoriteFn(mlsNumber);

        if (isFavorite) {
          await updateFavoriteById(mlsNumber, false, message);
          dispatch(favoriteActions.removeFavorite(mlsNumber));
          BroadcastService.getInstance().broadcast(
            BroadcastService.EVENT.REMOVE_FAVORITE,
            mlsNumber
          );
        } else {
          await updateFavoriteById(mlsNumber, true, message);
          dispatch(favoriteActions.addFavorite(mlsNumber));
          BroadcastService.getInstance().broadcast(
            BroadcastService.EVENT.ADD_FAVORITE,
            mlsNumber
          );
        }
      }
    },
    [dispatch, state.loading, isFavoriteFn, isAuthenticated]
  );

  const showFavPopup = useCallback(
    (mlsNumber: string) => {
      dispatch(favoriteActions.setPopupMlsNumber(mlsNumber));
    },
    [dispatch]
  );

  const handleFavoriteAction = useCallback(
    async (mlsNumber: string) => {
      if (authInfo?.permissions.includes('fav-admin')) {
        showFavPopup(mlsNumber);
      } else {
        await toggleFavorite(mlsNumber);
      }
    },
    [showFavPopup, toggleFavorite, authInfo]
  );

  return {
    favorites: state.favorites,
    loading: state.loading,
    isFavoriteFn,
    toggleFavorite,
    showFavPopup,
    handleFavoriteAction,
  };
};

export const useFavorite = () => {
  const { favorites, loading, isFavoriteFn, toggleFavorite } =
    useFavoriteInternal();

  return {
    favorites: favorites,
    loading: loading,
    isFavoriteFn,
    toggleFavorite,
  };
};

export const useSinglePropertyFavorite = (mlsNumber: string) => {
  const state = useSelector<AppState, FavoriteState>((state) => state.favorite);
  const {
    isFavoriteFn,
    showFavPopup,
    handleFavoriteAction: handleFavoriteActionInternal,
  } = useFavoriteInternal();
  const [isFavoriteUpdating, setIsFavoriteUpdating] = useState(false);

  const isFavorite = useMemo(
    () => isFavoriteFn(mlsNumber),
    [isFavoriteFn, mlsNumber]
  );

  const handleFavoriteAction = useCallback(async () => {
    setIsFavoriteUpdating(true);
    await handleFavoriteActionInternal(mlsNumber);
    setIsFavoriteUpdating(false);
  }, [handleFavoriteActionInternal, mlsNumber]);

  return {
    loading: state.loading,
    isFavorite,
    isFavoriteUpdating,
    showFavPopup,
    handleFavoriteAction,
  };
};
