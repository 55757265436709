import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from '@/context/auth/slice';
import Config from '@/config';
import { favoriteSlice } from '@/context/favorite/slice';
import { rootSlice } from '@/context/root/rootSlice';
import { compareSlice } from '@/context/compare/slice';
import { dialogSlice } from '@/context/dialog/slice';

const reducer = combineReducers({
  root: rootSlice.reducer,
  auth: authSlice.reducer,
  favorite: favoriteSlice.reducer,
  compare: compareSlice.reducer,
  dialog: dialogSlice.reducer,
});

const store = configureStore({
  reducer,
  devTools: !Config.IS_PRODUCTION,
});

export default store;
