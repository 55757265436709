import { useAuth } from '@/context/auth';
import AuthService from '@/services/auth.service';
import { AppState } from '@/types/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compareActions } from '../slice';
import { CompareState } from '../types';

export const useCompare = () => {
  const dispatch = useDispatch();
  const state = useSelector<AppState, CompareState>((state) => state.compare);
  const { isAuthenticated } = useAuth();

  const isCompareFn = useCallback(
    (mlsNumber: string) => {
      return !!state.items.find((property) => property.mlsNumber === mlsNumber);
    },
    [state.items]
  );

  const removeItem = useCallback(
    (mlsNumber: string) => {
      dispatch(compareActions.removeItem(mlsNumber));
    },
    [dispatch]
  );

  const removeAll = useCallback(() => {
    dispatch(compareActions.removeAll());
  }, [dispatch]);

  const toggleCompareItem = useCallback(
    async (mlsNumber: string) => {
      if (!isAuthenticated) {
        const authService = new AuthService();
        authService.openAuthModel();
      } else {
        const isFavorite = isCompareFn(mlsNumber);

        if (isFavorite) {
          dispatch(compareActions.removeItem(mlsNumber));
        } else {
          if (state.items.length >= 5) {
            alert('You can only compare 5 properties at a time.');
            return;
          }
          dispatch(compareActions.addItem(mlsNumber));
        }
      }
    },
    [dispatch, isCompareFn, isAuthenticated, state.items.length]
  );

  return {
    items: state.items,
    isCompareFn: isCompareFn,
    toggleCompareItem,
    removeItem,
    removeAll,
  };
};
