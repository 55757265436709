import React, { SVGProps } from 'react';

export default function IconYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5 13.3568C17.996 13.3568 13.4355 17.8387 13.4355 23.4214C13.4355 29.004 17.9173 33.4859 23.5 33.4859C29.0827 33.4859 33.5645 28.9254 33.5645 23.4214C33.5645 17.9173 29.004 13.3568 23.5 13.3568ZM23.5 29.8689C19.9617 29.8689 17.0524 26.9597 17.0524 23.4214C17.0524 19.8831 19.9617 16.9738 23.5 16.9738C27.0383 16.9738 29.9476 19.8831 29.9476 23.4214C29.9476 26.9597 27.0383 29.8689 23.5 29.8689Z"
        fill="currentColor"
      />
      <path
        d="M33.9577 15.4012C35.217 15.4012 36.2379 14.3803 36.2379 13.121C36.2379 11.8616 35.217 10.8407 33.9577 10.8407C32.6983 10.8407 31.6774 11.8616 31.6774 13.121C31.6774 14.3803 32.6983 15.4012 33.9577 15.4012Z"
        fill="currentColor"
      />
      <path
        d="M39.8548 7.22379C37.8105 5.10081 34.9012 4 31.5988 4H15.4012C8.56048 4 4 8.56048 4 15.4012V31.5202C4 34.9012 5.10081 37.8105 7.30242 39.9335C9.4254 41.9778 12.256 43 15.4798 43H31.5202C34.9012 43 37.7319 41.8992 39.7762 39.9335C41.8992 37.8891 43 34.9798 43 31.5988V15.4012C43 12.0988 41.8992 9.26814 39.8548 7.22379ZM39.5403 31.5988C39.5403 34.0363 38.6754 36.002 37.2601 37.3387C35.8448 38.6754 33.879 39.3831 31.5202 39.3831H15.4798C13.121 39.3831 11.1552 38.6754 9.73992 37.3387C8.3246 35.9234 7.61694 33.9577 7.61694 31.5202V15.4012C7.61694 13.0423 8.3246 11.0766 9.73992 9.66129C11.0766 8.3246 13.121 7.61694 15.4798 7.61694H31.6774C34.0363 7.61694 36.002 8.3246 37.4173 9.73992C38.754 11.1552 39.5403 13.121 39.5403 15.4012V31.5988Z"
        fill="currentColor"
      />
    </svg>
  );
}
