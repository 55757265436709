import { IContactModalProps } from './contact-modal';
import { IImageSliderModalProps } from './image-slider-modal';
import { ISaveSearchModalProps } from './save-search-modal';
import { ISaveSearchListModalProps } from './saved-search-list-modal';
import dynamic from 'next/dynamic';

export const ContactModal = dynamic<IContactModalProps>(() =>
  import('./contact-modal').then((mod) => mod.ContactModal)
);

export const FavoriteUserListModal = dynamic(() =>
  import('./favorite-user-list-modal').then((mod) => mod.FavoriteUserListModal)
);

export const ImageSliderModal = dynamic<IImageSliderModalProps>(() =>
  import('./image-slider-modal').then((mod) => mod.ImageSliderModal)
);

export const SaveSearchModal = dynamic<ISaveSearchModalProps>(() =>
  import('./save-search-modal').then((mod) => mod.SaveSearchModal)
);

export const SaveSearchListModal = dynamic<ISaveSearchListModalProps>(() =>
  import('./saved-search-list-modal').then((mod) => mod.SaveSearchListModal)
);

export const SignInModal = dynamic(() =>
  import('./sign-in-model').then((mod) => mod.SignInModal)
);
