import { List, ListProps } from '@/components/ui/lists';
import { ListItem } from '@/components/ui/listitem';
import clsx from 'clsx';

import InfoJson from '@/config/info.json';
import IconYoutube from '@/icons/icon-youtube';
import IconFacebook from '@/icons/icon-facebook';
import IconInstagram from '@/icons/icon-instagram';
import IconLinkedin from '@/icons/icon-linkedin';
import IconCalendar from '@/icons/icon-calendar';
import IconEmail from '@/icons/icon-email';

export const socialLinks = [
  {
    title: 'Youtube',
    icon: <IconYoutube width="36" height="36" />,
    path: InfoJson.youtube,
  },
  {
    title: 'Facebook',
    icon: <IconFacebook width="36" height="36" />,
    path: InfoJson.facebook,
  },
  {
    title: 'Instagram',
    icon: <IconInstagram width="36" height="36" />,
    path: `https://www.instagram.com/${InfoJson.instagramId}`,
  },
  {
    title: 'LinkedIn',
    icon: <IconLinkedin width="36" height="36" />,
    path: InfoJson.linkedin,
  },
  {
    title: 'Email',
    icon: <IconEmail width="36" height="36" />,
    path: `mailto:${InfoJson.email}`,
  },
  {
    title: 'Google Calendar',
    icon: <IconCalendar width="36" height="36" />,
    path: InfoJson['google-calendar'],
  },
];

export interface SocialLinksProps extends ListProps {
  listItemClasses?: string;
}

export function SocialLinks({
  className,
  listItemClasses,
  ...rest
}: SocialLinksProps) {
  return (
    <List
      className={clsx(
        'flex items-center flex-wrap px-4 justify-center',
        className
      )}
      {...rest}
    >
      {socialLinks.map((el) => (
        <ListItem className={clsx('ml-6', listItemClasses)} key={el.title}>
          <a
            href={el.path}
            title={`Open ${el.title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="h-6 w-6 md:h-6 md:w-6 relative">{el.icon}</div>
          </a>
        </ListItem>
      ))}
    </List>
  );
}
