import React, { SVGProps } from 'react';

export default function KeyboardArrowLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z"
        fill="currentColor"
      />
    </svg>
  );
}
