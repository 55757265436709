import { AppObject } from '@/types';
import * as Sentry from '@sentry/nextjs';

export function transformObjectToQuery(object: AppObject) {
  return Object.keys(object)
    .filter((x) => object[x])
    .map((x) => {
      if (Array.isArray(object[x])) {
        return (object[x] as string[]).map((y) => `${x}=${y}`).join('&');
      }
      return `${x}=${object[x]}`;
    })
    .join('&');
}

export async function withRetry<T>(
  fn: () => Promise<T>,
  retries = 1,
  delay = 1000
): Promise<T> {
  try {
    return await fn();
  } catch (e) {
    if (retries === 0) {
      throw e;
    }
    return await new Promise((resolve) => {
      setTimeout(() => resolve(withRetry(fn, retries - 1, delay)), delay);
    });
  }
}

interface BuildingTypeMap {
  [key: string]: string[];
}

const BUILDING_TYPE_MAP: BuildingTypeMap = {
  Detached: [
    'Detached',
    'Rural Resid',
    'Single Family',
    'Residential',
    'Single Family Residence',
  ],
  'Semi-Detached': ['Semi-Detached'],
  Townhouse: ['Att/Row/Twnhouse', 'Link', 'Row/Townhouse'],
  Condo: [
    'Co-Op Apt',
    'Co-Ownership Apt',
    'Comm Element Condo',
    'Condo Apt',
    'Condo Townhouse',
    'Det Condo',
    'Leasehold Condo',
    'Semi-Det Condo',
    'Vacant Land Condo',
    'Condo/Apt Unit',
    'Apartment Complex',
    'Apartment',
  ],
  Cottage: ['Cottage'],
  Land: ['Farm', 'Vacant Land'],
  Multiplex: [
    'Multiplex',
    'Triplex',
    'Duplex',
    'Fourplex',
    'Duplex Side/Side',
    'Duplex Up/Down',
    'Multi-10+Unit',
    'Multi-3 Unit',
    'Multi-4 Unit',
    'Multi-5 Unit',
    'Multi-6-9 Unit',
    'Multi Family',
  ],
  Other: [
    'Lower Level',
    'Parking Space',
    'Room',
    'Locker',
    'Time Share',
    'Upper Level',
    'Store W/Apt/Offc',
    'Mobile Home',
    'Mixed',
    'Modular Home',
    'Rooming House',
    'Other',
    'Mixed Use',
    'Agriculture',
    'Deeded Parking',
    'Shared Room',
  ],
};

export const buildingTypes = Object.keys(BUILDING_TYPE_MAP);
export function transformBuildingTypesForServer(
  buildingTypes: string[]
): string[] {
  return (buildingTypes || []).reduce((acc, curr) => {
    const items = BUILDING_TYPE_MAP[curr] || [];
    return acc.concat(items);
  }, [] as string[]);
}
export function getBuildingType(propertyType: string): string | null {
  if (!propertyType) return null;
  const buildingType = buildingTypes.find((x) =>
    BUILDING_TYPE_MAP[x].includes(propertyType)
  );
  return buildingType || null;
}

export function sentryCaptureException(error: unknown) {
  Sentry.captureException(error);
}
