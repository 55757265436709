import React, { SVGProps } from 'react';

export default function IconYoutube(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 16.6906L31.5847 24L19.5 31.3094V16.6906Z"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <rect
        x="1.5"
        y="8.5"
        width="45"
        height="31"
        rx="8.5"
        stroke="currentColor"
        strokeWidth="3"
      />
    </svg>
  );
}
